import React, { useContext, useEffect, useState } from "react";
import Container from "reactstrap/es/Container";
import Section from "../../components/Section";
import { Row } from "../../components/Grid";
import InputGroup from "../../components/InputGroup";
import BaseBlock from "../../components/BaseBlock";
import Button from "../../components/Button";
import { Form } from "reactstrap";
import { UserContext } from "../../contexts/user";
import CheckAuth from "../../components/blocks/CheckAuth";
import api from "../../utils/api";
import { Helmet } from "react-helmet/es/Helmet";
import { useToasts } from "react-toast-notifications";
import { useLocation } from "react-router-dom";

const Account = ({ ...props }) => {
  let { user, setUser } = useContext(UserContext);

  const [prenom, setPrenom] = useState(user.prenom);
  const [nom, setNom] = useState(user.nom);
  const [societe, setSociete] = useState(user.societe);
  const [adresse, setAdresse] = useState(user.adresse);
  const [cp, setCP] = useState(user.cp);
  const [ville, setVille] = useState(user.ville);
  const [errors, setErrors] = useState("");
  const [password, setPassword] = useState("");
  const [amountToAdd, setAmountToAdd] = useState("");
  const { addToast } = useToasts();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isCanceled = searchParams.get("canceled");
  const isSuccess = searchParams.get("success");

  useEffect(() => {
    if (isSuccess) {
      addToast("Le paiement a été effectué avec succès.", { appearance: "success" });
    } else if (isCanceled) {
      addToast("Le paiement a été annulé.", { appearance: "error" });
    }
  }, [isSuccess, isCanceled, addToast]);

  const _submit = async (e) => {
    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
    let data = {
      prenom,
      nom,
      societe,
      adresse,
      cp,
      ville,
      password,
    };

    let rep = await api.put(`/users/${user.id}`, data);

    if (rep.id) {
      setUser(rep);
      window.location.reload();
    } else if (rep.errors) setErrors(rep.errors);
    else if (rep.error_message) setErrors({ password: rep.error_message });
    else setErrors({ password: "Erreur d'enregistrement" });
  };

  const handleAmountChange = (value) => {
    const formattedValue = value.replace(",", ".");
    setAmountToAdd(formattedValue);
  };

  const redirectToPaymentPage = async () => {
    try {
      const amountInEuros = parseFloat(amountToAdd.replace(",", "."));
      const roundedAmount = parseFloat(amountInEuros.toFixed(2));

      let paymentSession = await api.post(`/users/${user.id}/paiements`, { amount: roundedAmount });
      if (paymentSession.url) {
        window.location = paymentSession.url;
      } else {
        addToast("Erreur lors de la création de la session de paiement.");
      }
    } catch (error) {
      addToast("Erreur lors de la création de la session de paiement.");
    }
  };

  return (
    <CheckAuth>
      <Helmet>
        <title>Mon compte Mail-Certificate</title>
        <meta
          name={"description"}
          content={"Mon compte Mail-Certificate, l'envoi de courrier électronique avec horodatage certifié eIDAS"}
        />
      </Helmet>
      <Container>
        <Section top>
          <h1>Mon compte</h1>
          <BaseBlock>
            <Form onSubmit={_submit}>
              <Row>
                <InputGroup
                  error={errors.prenom}
                  value={prenom}
                  onChangeText={setPrenom}
                  placeholder={"Prenom"}
                  icon={"fa fa-user-o"}
                />
                <InputGroup error={errors.nom} value={nom} onChangeText={setNom} placeholder={"Nom"} icon={"fa fa-user-o"} />
              </Row>
              <InputGroup
                error={errors.societe}
                value={societe}
                onChangeText={setSociete}
                placeholder={"Entreprise"}
                icon={"fa fa-building-o"}
              />
              <InputGroup
                placeholder={"Email*"}
                icon={"fa fa-envelope-o"}
                value={user.email}
                onChangeText={() => null}
                disabled={true}
              />
              <InputGroup
                error={errors.addresse}
                value={adresse}
                onChangeText={setAdresse}
                placeholder={"Adresse*"}
                icon={"fa fa-map-marker"}
              />
              <Row>
                <InputGroup
                  error={errors.cp}
                  value={cp}
                  onChangeText={setCP}
                  placeholder={"Code postal*"}
                  icon={"fa fa-map-marker"}
                />
                <InputGroup
                  error={errors.ville}
                  value={ville}
                  onChangeText={setVille}
                  placeholder={"Ville*"}
                  icon={"fa fa-map-marker"}
                />
              </Row>
              <BaseBlock t={0}>
                <InputGroup
                  error={errors.password}
                  value={password}
                  onChangeText={setPassword}
                  placeholder={"Mot de passe"}
                  icon={"fa fa-unlock-alt"}
                  type={"password"}
                />
              </BaseBlock>
              <Button right type={"submit"}>
                Enregistrer
              </Button>
            </Form>
          </BaseBlock>
          <BaseBlock id="credits">
            <h3>Crédit</h3>
            <p>Crédit disponible: {user.credit ? `${user.credit}€` : "0€"}</p>
            <InputGroup
              error={errors.credit}
              value={amountToAdd}
              onChangeText={handleAmountChange}
              placeholder={"Montant à ajouter"}
              icon={"fa fa-plus"}
            />
            <Button right onClick={redirectToPaymentPage}>
              Acheter du crédit
            </Button>
          </BaseBlock>
        </Section>
      </Container>
    </CheckAuth>
  );
};

const AccountWrapper = ({ ...props }) => {
  return (
    <CheckAuth>
      <Account />
    </CheckAuth>
  );
};

export default AccountWrapper;
