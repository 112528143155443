import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
	DropdownMenu,
	DropdownItem,
	DropdownToggle,
	UncontrolledDropdown,
	NavbarBrand,
	Navbar,
	NavItem,
	Nav,
	Container,
	Row,
	Col,
} from "reactstrap";
import { UserContext } from "../../contexts/user";
import Collapse from "reactstrap/es/Collapse";
import Button from "../Button";
import styled from "styled-components";

import { colors } from "../../utils/constants";
import { EmailContext } from "../../contexts/email";

const BNavbar = ({ ...props }) => {
	const [open, setOpen] = useState(false);

	const _open = () => {
		setOpen(true);
	};

	const _close = () => {
		setOpen(false);
	};

	return (
		<>
			<header className="header-global">
				<Navbar
					className="navbar navbar-light headroom"
					expand="lg"
					id="navbar-main"
					style={{ zIndex: 100, flexDirection: "column" }}
				>
					<Container>
						<NavbarBrand className="mr-lg-5" to="/" tag={Link}>
							<img alt="Mail-Certificate" src={require("assets/img/brand/logo1.png")} />
						</NavbarBrand>
						<button className="navbar-toggler" id="navbar_global" onClick={_open}>
							<span className="navbar-toggler-icon" />
						</button>

						<Collapse toggler="#navbar_global" navbar isOpen={open} timeout={0}>
							<div className="navbar-collapse-header">
								<Row>
									<Col className="collapse-brand" xs="6">
										<Link to="/">
											<img alt="Mail-Certificate" src={require("assets/img/brand/logo1.png")} />
										</Link>
									</Col>
									<Col className="collapse-close" xs="6">
										<button className="navbar-toggler" id="navbar_global" onClick={_close}>
											<span />
											<span />
										</button>
									</Col>
								</Row>
							</div>
							<Nav className="navbar-nav-hover align-items-lg-center" navbar onClick={_close}>
								<Link to={"/faq"} className={"nav-link"}>
									Aide
								</Link>
							</Nav>
							<Nav className="navbar-nav-hover align-items-lg-center ml-lg-auto" navbar>
								<ProfileElement onToggle={_close} />
								<NavItem className="d-none d-lg-block ml-lg-4" onClick={_close}></NavItem>
							</Nav>
						</Collapse>
					</Container>
				</Navbar>
			</header>
		</>
	);
};

const ProfileElement = ({ onToggle }) => {
	const { user, logout, logged_in, fetched } = useContext(UserContext);
	const isAdmin = user && user.is_admin;
	const { setType } = useContext(EmailContext);

	const ButtonSignUpStyled = styled(Button)`
		width: 100px;
		display: inline;
		white-space: nowrap;
		border: 1px solid ${colors.primary};
		margin-right: 20px;
		padding: 0.5rem 1rem;
		&:hover {
			background-color: ${colors.danger};
			color: white;
			border: none;
		}
	`;

	const ButtonLoginStyled = styled(Button)`
		display: inline;
		white-space: nowrap;
		background-color: ${colors.primary};
		color: white;
		padding: 0.5rem 1rem;
		&:hover {
			background-color: ${colors.success};
			color: white;
			border: 1px solid ${colors.success};
		}
	`;

	const handleSetType = (type) => {
		setType(type);
		onToggle();
	};

	return logged_in ? (
		<>
			<a href="/compte#credits">
				<Button className="btn btn-sm">Acheter du Crédit</Button>
			</a>
			<UncontrolledDropdown nav>
				<DropdownToggle nav>
					<i className="fa fa-user-o" />
					<span className="nav-link-inner--text">{user.display_name}</span>
				</DropdownToggle>
				<DropdownMenu>
					{isAdmin && (
						<DropdownItem to="/admin" tag={Link} onClick={onToggle}>
							Admin - Back Office
						</DropdownItem>
					)}
					<DropdownItem to="/compte" tag={Link} onClick={onToggle}>
						Mon compte
					</DropdownItem>
					<DropdownItem to="/compte/historique-emails" tag={Link} onClick={onToggle}>
						Mes courriers envoyés
					</DropdownItem>
					<DropdownItem to="/compte/contacts" tag={Link} onClick={onToggle}>
						Mes contacts
					</DropdownItem>
					<DropdownItem to="/envoi-destinataire" tag={Link} onClick={() => handleSetType("plus")}>
						Envoyer un mail Plus
					</DropdownItem>
					<DropdownItem to="/envoi-destinataire" tag={Link} onClick={() => handleSetType("standard")}>
						Envoyer un mail Standard
					</DropdownItem>
					<DropdownItem onClick={() => onToggle() || logout()}>Déconnexion</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown>
		</>
	) : fetched ? (
		<div style={{ display: "flex" }}>
			<ButtonSignUpStyled to={"/inscription"} tag={Link} type={"submit"} className="btn btn-sm">
				S'inscrire
			</ButtonSignUpStyled>
			<ButtonLoginStyled to={"/connexion"} tag={Link} type={"submit"} className="btn btn-sm">
				Se connecter
			</ButtonLoginStyled>
		</div>
	) : (
		<div />
	);
};

export default BNavbar;
