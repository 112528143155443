import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Container from "reactstrap/es/Container";
import Alert from "reactstrap/es/Alert";
import Section from "../components/Section";
import api, { API_BASE_URL } from "../utils/api";
import InternalAd from "../components/blocks/InternalAd";
import BaseBlock from "../components/BaseBlock";
import { Helmet } from "react-helmet/es/Helmet";

const AcceptedWrapper = styled(Container)``;

const Accepted = ({ ...props }) => {
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [recipient, setRecipient] = useState({});

  useEffect(() => {
    _doAccept();
  }, []);

  const _doAccept = async () => {
    let rep = await api.post(`/recipients/${props.match.params.recipient_id}/accept`, {
      token: props.match.params.recipient_token,
    });

    if (rep.error_message) setError(rep.error_message);
    else {
      if (typeof window.gtag !== "undefined")
        window.gtag("event", "accepted", {
          event_category: "email",
          event_label: props.match.params.recipient_id,
          value: 1,
        });
      setRecipient(rep);
    }

    setLoaded(true);
  };

  return (
    <AcceptedWrapper>
      <Section top>
        <Helmet>
          <title>Acceptation de votre email avec horodatage certifié eIDAS</title>
          <meta name={"description"} content={"Acceptez vos courriers électroniques avec horodatage certifié eIDAS."} />
        </Helmet>
        <h1>Acceptation de votre email avec horodatage certifié eIDAS</h1>
        {loaded && (
          <div>
            {error ? (
              <div>
                <Alert className={"alert-danger"}>{error}</Alert>
              </div>
            ) : (
              <div>
                <Alert>
                  Vous avez accepté un courrier électronique avec horodatage certifié eIDAS. Vous recevrez le courrier par email
                  dans quelques instants.
                </Alert>
                <div className="lead">Vous pouvez également télécharger les fichiers ci-dessous :</div>
                <BaseBlock>
                  <a
                    onClick={() =>
                      (window.location = `${API_BASE_URL}/recipients/${recipient.id}/${props.match.params.recipient_token}/pdf`)
                    }>
                    Télécharger le courrier
                  </a>
                  {recipient.attachments.map((attachment) => {
                    return (
                      <div>
                        <a
                          onClick={() =>
                            (window.location = `${API_BASE_URL}/recipients/${recipient.id}/${props.match.params.recipient_token}/attachments/${attachment.id}`)
                          }>
                          {attachment.name}
                        </a>
                      </div>
                    );
                  })}
                </BaseBlock>
              </div>
            )}
          </div>
        )}
        <Section>
          <InternalAd />
        </Section>
      </Section>
    </AcceptedWrapper>
  );
};

export default Accepted;
