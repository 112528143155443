const colors = {
    primary:'#3254ce',
    secondary:'#0f385c',
    success:'#39cd8b',
    danger:'#e35068'
};

const contrastColors = {
    primary:'#FFFFFF',
    secondary:'#000000'
};

export const size = {
    xs: '576px',
    sm: '768px',
    md: '992px',
    lg: '1200px',
};

export const device = {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
};

export {colors, contrastColors};