import React from "react";
import Container from "reactstrap/es/Container";
import { Helmet } from "react-helmet/es/Helmet";
import Section from "../../components/Section";
import InternalAd from "../../components/blocks/InternalAd";

const Faq = ({ ...props }) => {
  return (
    <Container>
      <Helmet>
        <title>Foire aux questions</title>
        <meta name={"description"} content={"Tout savoir sur le courrier électronique avec horodatage certifié eIDAS"} />
      </Helmet>
      <Section top>
        <div className="hdrd">
          <h1>Foire aux questions</h1>
        </div>
        <h2>
          <br />
          Puis-je envoyer le même courrier avec horodatage certifié eIDAS à plusieurs destinataires ?
        </h2>
        <p>
          Oui, de plus avec nous les envois groupés sont possibles ! Ajoutez autant de destinataires que nécessaire à votre envoi.
          Seuls les bulletins de paye ne peuvent avoir qu’un seul destinataire.
        </p>
        <p>&nbsp;</p>
        <h2>Quels formats de fichiers peut-on envoyer ?</h2>
        <p>
          Mail-Certificate est compatible avec tous les formats de fichiers existants sans aucune restriction, avec une taille
          limitée à 10 Mo
        </p>
        <p>&nbsp;</p>
        <h2>Email avec horodatage certifié eIDAS est-il réservé aux particuliers ou aux professionnels ?</h2>
        <p>Non, le service Mail-certificate est accessible à tous, personnes physiques ou morales, sans restriction et 24/7</p>
        <p>&nbsp;</p>
        <h2>
          Faut-il etre client pour envoyer ou recevoir un courrier électronique avec horodatage certifié eIDAS sur
          mail-certificate ?
        </h2>
        <p>
          Le service Mail-certificate étant en libre accès, il suffit de s’inscrire en indiquant ses coordonnées.Notre service est
          accessible à tous sans engagement.
        </p>
        <p>&nbsp;</p>
        <h2>Comment donner acces au service a vos collaborateurs ?</h2>
        <p>Le service Mail-certificate étant en libre accès, chaque personne peut avoir son propre compte.</p>
        <p>&nbsp;</p>
        <h2>Quelle est la duree de conservation des documents ?</h2>
        <p>
          Mail-certificate conserve vos courriers électroniques avec horodatages certifiés eIDAS ainsi que toutes les preuves de
          dépôts pendant la durée légale d’un an.
        </p>
        <p>&nbsp;</p>
        <h2>Est-il possible d’envoyer un courrier avec horodatage certifié eIDAS en format papier ?</h2>
        <p>Le service Mail-certificate est entièrement électronique et ne prévoit pas de distribution postale classique.</p>
        <p>&nbsp;</p>
        <h2>Mon destinataire doit-il etre inscrit sur mal-certificate?</h2>
        <p>
          Non, son inscription n’est pas nécessaire pour envoyer un courrier électronique avec horodatage certifié eIDAS. Votre
          destinataire sera cependant invité à s’inscrire à son tour, et ainsi obtenir son accord pour recevoir le courrier
          électronique et garantir son identité.
        </p>
        <p>&nbsp;</p>
        <h2>Est-ce que je peux utiliser mail-certificate depuis l’étranger ?</h2>
        <p>
          Oui, avec Mail-Certificate vous pouvez envoyer vos courriers avec horodatages certifiés eIDAS depuis n'importe quel
          endroit de la planète.
        </p>
        <p>&nbsp;</p>
        <h2>Est-ce que je peux utiliser mail-certificate a partir d’un autre ordinateur que le mien ?</h2>
        <p>
          Bien sûr, vous pouvez accéder à votre messagerie depuis n’importe quel ordinateur, dans le monde entier. Connectez-vous
          puis renseignez votre adresse email et votre mot de passe.
        </p>
        <p>&nbsp;</p>
        <h2>Quand vais-je recevoir mes preuves de dépôt de courrier avec horodatages certifiés eIDAS ?</h2>
        <p>
          Les preuves de votre dépôt à Mail-Certificate, du consentementà recevoir de la part du destinataire, de l’envoi ou de
          l’acceptation dudit courrier seront jointes aux emails de confirmation qui vous seront envoyés en temps réel.
        </p>
        <p>&nbsp;</p>
        <h2>A quoi correspond l'heure utc ?</h2>
        <p>
          L'heure UTC (Universal Time Coordinated), en français "Temps Universel Coordonné", est l'heure de référence
          internationale. Elle correspond aussi à l'heure GMT (Greenwich Mean Time). En France, en heure d'été, l'heure légale est
          en avance de 2 heures sur l'heure UTC. A l'heure d'hiver, l'heure légale est en avance d'une heure sur l'heure UTC.
        </p>
        <p></p>
        <InternalAd />
      </Section>
    </Container>
  );
};

export default Faq;
