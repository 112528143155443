import React, { useContext, useState } from "react";
import styled from "styled-components";
import Container from "reactstrap/es/Container";
import Section from "../components/Section";
import Form from "reactstrap/es/Form";
import InputGroup from "../components/InputGroup";
import Button from "../components/Button";
import api from "../utils/api";
import ErrorText from "../components/ErrorText";
import { UserContext } from "../contexts/user";
import { Helmet } from "react-helmet/es/Helmet";

const ResetPasswordWrapper = styled(Container)``;

const ResetPassword = ({ ...props }) => {
  const [error, setError] = useState("");
  const { setUser } = useContext(UserContext);
  const [password, setPassword] = useState("");
  const [passConfirm, setPassConfirm] = useState("");

  const _submit = async (e) => {
    e.preventDefault ? e.preventDefault() : (e.returnValue = false);

    if (password !== passConfirm) {
      setError("La confirmation doit correspondre au mot de passe.");
      return;
    }

    let rep = await api.post("/users/reset", {
      password,
      user_id: props.match.params.user_id,
      token: props.match.params.token,
    });

    if (!!rep && rep.id) {
      setUser(rep);
      props.history.push("/");
    } else {
      setError(rep.error_message);
    }
  };

  return (
    <ResetPasswordWrapper>
      <Helmet>
        <title>
          Réinitialisez votre mot de passe Mail-Certificate l'envoi de courrier électronique avec horodatage certifié eIDAS
        </title>
        <meta
          name={"description"}
          content={
            "Réinitialisez votre mot de passe Mail-Certificate l'envoi de courrier électronique avec horodatage certifié eIDAS"
          }
        />
      </Helmet>
      <Section top>
        <Form onSubmit={_submit}>
          <h1>Réinitialisation du mot de passe</h1>
          <InputGroup
            value={password}
            onChangeText={setPassword}
            placeholder={"Mot de passe"}
            icon={"fa fa-lock"}
            type={"password"}
          />
          <InputGroup
            value={passConfirm}
            onChangeText={setPassConfirm}
            placeholder={"Confirmation du mot de passe"}
            icon={"fa fa-lock"}
            type={"password"}
          />
          <div className="form-group">
            <Button right type={"submit"}>
              Continuer
            </Button>
          </div>
          <ErrorText>{error}</ErrorText>
        </Form>
      </Section>
    </ResetPasswordWrapper>
  );
};

export default ResetPassword;
