import React from "react";
import styled from "styled-components";
import Container from "reactstrap/es/Container";
import Section from "../../../components/Section";
import { Helmet } from "react-helmet/es/Helmet";
import InternalAd from "../../../components/blocks/InternalAd";

const AWrapper = styled(Container)``;

const A = ({ ...props }) => {
  return (
    <AWrapper>
      <Helmet>
        <title>Le courrier avec horodatage certifié eIDAS en ligne - description et conformité</title>
        <meta
          name={"description"}
          content={"Envoyer un courrier certifié  de son bureau ou de chez soi est simple et pratique !"}
        />
      </Helmet>
      <Section top>
        <h2>Le courrier électronique avec horodatage certifié eIDAS.</h2>

        <p></p>
        <p>
          Envoyer un courrier avec horodatage certifié eIDAS de son bureau ou de chez soi est simple et pratique. Inutile de se
          déplacer et de patienter derrière un guichet pour envoyer une lettre recommandée. Les services du site mail-certificate
          vous évite tout déplacement désormais inutile.
        </p>
        <p>
          Ce service d’envoi de courrier avec horodatage certifié eIDAS par internet est également moins cher. Les frais de
          distribution et d’acheminement n’ont en effet plus lieu d’être. Le chiffrement des certifications et le stockage des
          fichiers engendrent des coûts plus faibles, créant une véritable opportunité pour les consommateurs.
        </p>
        <p>
          Tout courrier avec horodatage certifié eIDAS est envoyé avec une clé d’authentification qui permet d’authentifier le
          courrier.
        </p>
        <p></p>
        <InternalAd />
      </Section>
    </AWrapper>
  );
};

export default A;
