import React from "react";
import Container from "reactstrap/es/Container";
import Section from "../../components/Section";
import ContactList from "../../components/blocks/ContactList";
import BaseBlock from "../../components/BaseBlock";
import CheckAuth from "../../components/blocks/CheckAuth";
import { Helmet } from "react-helmet/es/Helmet";

const Contacts = ({ ...props }) => {
  return (
    <CheckAuth>
      <Helmet>
        <title>Ma liste de contacts</title>
        <meta
          name={"description"}
          content={"Ma liste de contacts Mail-certificate, l'envoi de courrier électronique avec horodatage certifié eIDAS."}
        />
      </Helmet>
      <Container>
        <Section top>
          <BaseBlock t={0}>
            <h1>Mes contacts</h1>
          </BaseBlock>
          <BaseBlock>
            <ContactList editable={false} deletable={true} />
          </BaseBlock>
        </Section>
      </Container>
    </CheckAuth>
  );
};

export default Contacts;
