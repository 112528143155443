import React from "react";
import Container from "reactstrap/es/Container";
import Section from "../../../components/Section";
import { Helmet } from "react-helmet/es/Helmet";
import InternalAd from "../../../components/blocks/InternalAd";

const B = ({ ...props }) => {
  return (
    <Container>
      <Section top>
        <Helmet>
          <title>Courrier avec horodatage certifié eIDAS en ligne</title>
          <meta
            name={"description"}
            content={
              "L'envoi d'un courrier électronique certifié par horodatage en ligne à partir de tout lieu disposant d'une connection internet"
            }
          />
        </Helmet>
        <h2>Courrier avec horodatage certifié eIDAS</h2>

        <p></p>
        <p>
          Le courrier avec horodatage certifié eIDAS avec accusé de réception est désormais réalisable en ligne grâce à internet.
          L'envoi d'un courrier électoronique avec horodatage certifié eIDAS est possible à tout moment et à partir de tout lieu
          disposant d'une connection internet. Et moins cher qu'un envoi de courrier recommandé ou qu’une lettre recommandée !
        </p>
        <p></p>
        <InternalAd />
      </Section>
    </Container>
  );
};

export default B;
